<template>
    <div>Logging in…</div>
</template>


<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'OidcCallback',

        computed: {
            ...mapGetters([
                'oidcUser',
                'oidcIsAuthenticated',
            ]),

            hasAccess: function() {
                return this.oidcIsAuthenticated || this.$route.meta.isPublic
            }
        },

        methods: {
            ...mapActions([
                'oidcSignInCallback',
                'oidcStore',
                'getUser',
            ])
        },

        mounted() {
            this.oidcSignInCallback()
                .then((redirectPath) => {
                    if (this.hasAccess) {
                        this.getUser(this.oidcUser.oid)
                    }
                    const redirect = decodeURIComponent(redirectPath).replace('#', '?');

                    this.$router.push(redirect)
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error(err)
                    this.$router.push('/oidc-callback-error') // Handle errors any way you want
                })
        }
    }
</script>